import { SelectItem } from "@mantine/core/lib/Select/types";
import get from "lodash/get";

import {
  PaymentStatus,
  PaymentType,
  TransactionHistory as GqlGeneratedTransactionHistory,
  TransactionHistoryOrderStatus,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";
import { Invoice } from "./invoice.ts";
import { LimitRequestConditionPrecedent } from "./limitRequestConditionPrecedent.ts";
import { LimitRequestException } from "./limitRequestException.ts";
import { User } from "./user.ts";

export type TransactionHistory = Omit<
  GqlGeneratedTransactionHistory,
  "limit" | "limitId" | "invoice" | "invoiceId" | "approvedBy" | "rejectedBy"
> & {
  invoice?: Partial<Invoice> | null | undefined;
  limit?:
    | {
        limitName?: string | null | undefined;
        limitRequest?:
          | {
              id?: number;
              conditionPrecedents?:
                | LimitRequestConditionPrecedent[]
                | null
                | undefined;
              exceptions?: LimitRequestException[] | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  approvedBy?: Pick<User, "name"> | null | undefined;
  rejectedBy?: Pick<User, "name"> | null | undefined;
};

export const paymentStatusData: SelectItem[] = Object.keys(PaymentStatus).map(
  (key) => ({
    value: get(PaymentStatus, key),
    label: toHumanReadable(get(PaymentStatus, key)),
  })
);

export const paymentTypeData = [
  {
    value: PaymentType.Top,
    label: "Term of Payment (TOP)",
  },
  {
    value: PaymentType.Cod,
    label: "Cash on Delivery (COD)",
  },
  {
    value: PaymentType.Cbd,
    label: "Cash Before Delivery (CBD)",
  },
];

export const orderStatusMapping: Record<TransactionHistoryOrderStatus, string> =
  {
    [TransactionHistoryOrderStatus.Draft]: "Draft",
    [TransactionHistoryOrderStatus.Cancel]: "Cancel",
    [TransactionHistoryOrderStatus.Rfq]: "RFQ",
    [TransactionHistoryOrderStatus.PoSubmission]: "PO Submission",
    [TransactionHistoryOrderStatus.ProformaInvoice]: "Proforma Invoice",
    [TransactionHistoryOrderStatus.Quotation]: "Quotation",
    [TransactionHistoryOrderStatus.SalesOrder]: "Sales Order",
  };
