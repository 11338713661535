import { SelectItem } from "@mantine/core/lib/Select/types";

import {
  DpdBucket,
  DpdBucketAccumulative,
  PengurusGender,
} from "./graphql/generated";
import { toHumanReadable } from "./utils/string.ts";

export enum AppRoute {
  Home = "/",
  Login = "/login",
  AccessDenied = "/access-denied",
  UserAccessSetting = "/user-access-setting",
  ActivityManagement = "/activity-management",
  CollectionDashboard = "/collection-dashboard",
  ReviewAndApproval = "/review-and-approval",
  LimitRequest = "/limit-request",
  LimitRequestCreate = `${AppRoute.LimitRequest}/create`,
  ClikData = "/clik-data",
  ClikResult = "/clik-result",
  EmployeeCandidate = "/employee-candidate",
  EmployeeCandidateCreate = `${AppRoute.EmployeeCandidate}/create`,
  RiskFunnelDashboard = "/risk-funnel-dashboard",
  CreditUnderwriting = "/credit-underwriting",
  RiskReview = "/risk-review",
  RiskMonitoring = "/risk-monitoring",
}

export const dpdBucketOptions: SelectItem[] = [
  { value: DpdBucket.Current, label: "Current" },
  { value: DpdBucket.OneSeven, label: "1-7" },
  { value: DpdBucket.EightFifteen, label: "8-15" },
  { value: DpdBucket.SixteenThirty, label: "16-30" },
  { value: DpdBucket.ThirtyoneSixty, label: "31-60" },
  { value: DpdBucket.SixtyoneNinety, label: "61-90" },
  { value: DpdBucket.NinetyPlus, label: "90+" },
];

export const dpdBucketAccumulativeOptions: SelectItem[] = [
  { value: DpdBucketAccumulative.SevenPlus, label: "DPD 7+" },
  { value: DpdBucketAccumulative.FifteenPlus, label: "DPD 15+" },
  { value: DpdBucketAccumulative.ThirtyPlus, label: "DPD 30+" },
  { value: DpdBucketAccumulative.SixtyPlus, label: "DPD 60+" },
  { value: DpdBucketAccumulative.NinetyPlus, label: "DPD 90+" },
];

export const CURRENCY = "IDR";

export const genderOptions = Object.values(PengurusGender).map((item) => ({
  value: item,
  label: toHumanReadable(item),
}));

export const boolSelectorOptions = [
  {
    value: "true",
    label: "True",
  },
  {
    value: "false",
    label: "False",
  },
];

export const yesNoOptions = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];
