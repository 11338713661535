import { Flex } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import SvgWarning from "../../../components/Icons/Warning.tsx";
import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../components/Table/Table.tsx";
import { SortDirection, TableRow } from "../../../components/Table/types.ts";
import { AppRoute } from "../../../constants.ts";
import {
  LimitRequestOrderBy,
  LimitRequestStatusFilterOptions,
  OrderBy,
} from "../../../graphql/generated.ts";
import { useLimitRequests } from "../../../hooks/api/limitRequest/useLimitRequests.ts";
import { LimitRequest } from "../../../types/limitRequest.ts";
import { getPagesCount } from "../../../utils/pagination.ts";
import { getRiskMonitoringApiSort } from "../utils.ts";
import { useOngoingOLTableData } from "./hooks/useOngoingOLTableData.tsx";

const OngoingOLTable = () => {
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState<LimitRequestOrderBy>({
    createdAt: OrderBy.Desc,
  });
  const [page, setPage] = useState<number>(1);

  const [{ data, fetching, error }] = useLimitRequests({
    filter: {
      requestStatus: {
        equals: LimitRequestStatusFilterOptions.Ol,
      },
    },
    orderBy,
    page,
  });

  const ongoingOLData: LimitRequest[] | null | undefined =
    data?.limitRequests?.data;

  const pageCount = getPagesCount(data?.limitRequests?.total);

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleRowClik = useCallback(
    (record: TableRow) => {
      navigate(`${AppRoute.LimitRequest}/${record.id}`);
    },
    [navigate]
  );

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getRiskMonitoringApiSort(sort));
    },
    [setOrderBy]
  );

  const [columns, rows] = useOngoingOLTableData({
    data: ongoingOLData,
  });

  if (error) {
    notifications.clean();
    notifications.show({
      message: "Something went wrong while trying to fetch Ongoing OL data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <Flex direction="column" gap={12}>
      <SectionTitle>Ongoing OL</SectionTitle>
      <Table
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "createdAt",
          direction: SortDirection.asc,
        }}
        loading={fetching}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onRowClick={handleRowClik}
        onSortChange={handleSortChange}
      />
    </Flex>
  );
};

export default OngoingOLTable;
