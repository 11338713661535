import { Flex } from "@mantine/core";

import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import NewRRAnalysisTable from "./NewRRAnalysis/NewRRAnalysisTable.tsx";
import OngoingCCTable from "./OngoingCC/OngoingCCTable.tsx";
import OngoingOLTable from "./OngoingOL/OngoingOLTable.tsx";

const RiskMonitoringPage = () => {
  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Risk Monitoring</Title>
        <NewRRAnalysisTable />
        <OngoingCCTable />
        <OngoingOLTable />
      </Flex>
    </Layout>
  );
};

export default RiskMonitoringPage;
