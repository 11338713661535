import { ActionIcon, Button, Flex, Tabs } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { isNull } from "lodash";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider.tsx";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal.tsx";
import SvgChevronLeft from "../../components/Icons/ChevronLeft.tsx";
import SvgSnowflake from "../../components/Icons/Snowflake.tsx";
import SvgWarning from "../../components/Icons/Warning.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import Title from "../../components/Title/Title.tsx";
import { AppRoute } from "../../constants.ts";
import { CustomerFreezeStatus } from "../../graphql/generated.ts";
import { useClikCities } from "../../hooks/api/clikData/useClikCities.ts";
import { useClikCountries } from "../../hooks/api/clikData/useClikCountries.ts";
import { useClikData } from "../../hooks/api/clikData/useClikData.ts";
import { useCompanyOptions } from "../../hooks/api/customer/useCompanyOptions.ts";
import { useFreezeCustomerStatus } from "../../hooks/api/customer/useFreezeCustomerStatus.ts";
import { useUnFreezeCustomerStatus } from "../../hooks/api/customer/useUnFreezeCustomerStatus.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { useStyles } from "./ClikDataDetailsPage.styles.ts";
import ClikResultList from "./ClikResultList/ClikResultList.tsx";
import CompanyInfo from "./CompanyInfo/CompanyInfo.tsx";
import { tabs, TabValue } from "./constants.tsx";
import LimitInfo from "./LimitInfo/LimitInfo.tsx";
import PengurusInfo from "./PengurusInfo/PengurusInfo.tsx";
import TransactionHistoryList from "./TransactionHistory/TransactionHistoryList.tsx";

interface Props {
  tabIds?: string[];
}

const ClikDataDetailsPage = ({
  tabIds = [
    TabValue.CompanyInfo,
    TabValue.PengurusInfo,
    TabValue.ClikResult,
    TabValue.LimitInfo,
    TabValue.TransactionHistory,
  ],
}: Props) => {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const { user } = useCurrentUserContext();

  const { id } = useParams() as { id: string };

  const [
    { data: clikDataResponse, fetching: clikDataFetching, error },
    refreshClikData,
  ] = useClikData({ id });

  const [{ data: clikCitiesData, fetching: clikCitiesFetching }] =
    useClikCities();

  const [{ data: clikCountriesData, fetching: clikCountriesFetching }] =
    useClikCountries();

  const [{ data: companiesData, fetching: companiesFetching }] =
    useCompanyOptions();

  const [, freezeCustomerStatus] = useMutationNotificationWrapper(
    useFreezeCustomerStatus(),
    {
      success: {
        message: "Customer Freeze Status has been changed successfully.",
      },
    }
  );

  const [, unFreezeCustomerStatus] = useMutationNotificationWrapper(
    useUnFreezeCustomerStatus(),
    {
      success: {
        message: "Customer Freeze Status has been changed successfully.",
      },
    }
  );

  const [
    showFreezeConfirmationModal,
    { open: openFreezeConfirmationModal, close: closeFreezeConfirmationModal },
  ] = useDisclosure(false);

  const clikData = clikDataResponse?.clikData;
  const limitRequest = clikData?.limitRequest;
  const isCustomerFreezed =
    limitRequest?.company?.freezeStatus === CustomerFreezeStatus.Active;

  const isFetching = useMemo(() => {
    return (
      clikCitiesFetching ||
      clikCountriesFetching ||
      companiesFetching ||
      clikDataFetching
    );
  }, [
    clikCitiesFetching,
    companiesFetching,
    clikCountriesFetching,
    clikDataFetching,
  ]);

  const displayTabs = tabs
    .filter((item) => (item.show ? item.show(user) : true))
    .filter((tab) => tabIds?.includes(tab.value));

  const handleBack = () => navigate(-1);

  if (error || isNull(clikData)) {
    navigate(AppRoute.ClikData);
    notifications.show({
      message: "Something went wrong while trying to fetch data.",
      icon: <SvgWarning />,
    });
  }

  const handleChangeFreezeCustomerStatus = useCallback(() => {
    if (!limitRequest?.company) {
      return;
    }
    const handler = isCustomerFreezed
      ? unFreezeCustomerStatus
      : freezeCustomerStatus;
    handler({
      id: limitRequest.company.id,
    }).then(() => {
      closeFreezeConfirmationModal();
      refreshClikData();
    });
  }, [
    closeFreezeConfirmationModal,
    freezeCustomerStatus,
    isCustomerFreezed,
    limitRequest?.company,
    refreshClikData,
    unFreezeCustomerStatus,
  ]);

  const renderFreezeStatusCustomerButton = () => {
    if (isCustomerFreezed) {
      return (
        <Button
          variant="outlineGolden"
          uppercase
          onClick={openFreezeConfirmationModal}
        >
          Unfreeze Customer
        </Button>
      );
    }
    return (
      <Button
        variant="filledGolden"
        leftIcon={<SvgSnowflake />}
        uppercase
        onClick={openFreezeConfirmationModal}
      >
        Freeze Customer
      </Button>
    );
  };

  return (
    <Layout>
      <Preloader loading={isFetching} />
      <Flex direction="column" gap={{ base: 24, sm: 20 }}>
        <Flex gap={20} justify="space-between">
          <Flex gap={20}>
            <ActionIcon size="m" variant="outlineGolden" onClick={handleBack}>
              <SvgChevronLeft />
            </ActionIcon>
            <Title className={classes.titleWrapper}>
              {limitRequest?.company?.name
                ? `Buyer Information - ${limitRequest.company.name}`
                : "Buyer Information"}
            </Title>
          </Flex>
          {renderFreezeStatusCustomerButton()}
        </Flex>
        <Tabs
          defaultValue={displayTabs?.[0]?.value}
          keepMounted={false}
          variant="outline"
        >
          <Tabs.List>
            {displayTabs.map((item, index) => {
              return (
                <Tabs.Tab key={index} value={item.value}>
                  {item.label}
                </Tabs.Tab>
              );
            })}
          </Tabs.List>
          <Tabs.Panel value={TabValue.CompanyInfo}>
            <CompanyInfo
              limitRequest={limitRequest}
              cities={clikCitiesData?.clikCities}
              countries={clikCountriesData?.clikCountries}
              companies={companiesData?.customers}
              isFetching={isFetching}
              onRefresh={refreshClikData}
            />
          </Tabs.Panel>
          <Tabs.Panel value={TabValue.PengurusInfo}>
            <PengurusInfo
              limitRequestId={limitRequest?.id}
              loading={isFetching}
              cities={clikCitiesData?.clikCities}
              countries={clikCountriesData?.clikCountries}
            />
          </Tabs.Panel>
          <Tabs.Panel value={TabValue.ClikResult}>
            <ClikResultList clikDataId={clikData?.id} />
          </Tabs.Panel>
          <Tabs.Panel value={TabValue.LimitInfo}>
            <LimitInfo company={limitRequest?.company} />
          </Tabs.Panel>
          <Tabs.Panel value={TabValue.TransactionHistory}>
            <TransactionHistoryList customer={limitRequest?.company} />
          </Tabs.Panel>
        </Tabs>
      </Flex>
      {showFreezeConfirmationModal && (
        <ConfirmationModal
          opened={true}
          message={`Are you sure you want to ${
            isCustomerFreezed ? "Unfreeze" : "Freeze"
          } this Customer?`}
          onConfirm={handleChangeFreezeCustomerStatus}
          onClose={closeFreezeConfirmationModal}
        />
      )}
    </Layout>
  );
};

export default ClikDataDetailsPage;
