import { Flex, Select, SimpleGrid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useMemo, useState } from "react";

import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal.tsx";
import Table from "../../../components/Table/Table.tsx";
import { SortDirection } from "../../../components/Table/types.ts";
import { boolSelectorOptions } from "../../../constants.ts";
import {
  OrderBy,
  PaymentStatus,
  TransactionHistoryOrderBy,
  UpdateTransactionHistoryInput,
} from "../../../graphql/generated.ts";
import { useCustomers } from "../../../hooks/api/customer/useCustomers.ts";
import { useApproveTransactionHistory } from "../../../hooks/api/transactionHistory/useApproveTransactionHistory.ts";
import { useGetTransactionHistoryList } from "../../../hooks/api/transactionHistory/useGetTransactionHistoryList.ts";
import { useRejectTransactionHistory } from "../../../hooks/api/transactionHistory/useRejectTransactionHistory.ts";
import { useUpdateTransactionHistory } from "../../../hooks/api/transactionHistory/useUpdateTransactionHistory.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { Customer } from "../../../types/customer.ts";
import { LimitRequestConditionPrecedent } from "../../../types/limitRequestConditionPrecedent.ts";
import { LimitRequestException } from "../../../types/limitRequestException.ts";
import {
  paymentStatusData,
  paymentTypeData,
  TransactionHistory,
} from "../../../types/transactionHistory.ts";
import { stringToBoolean } from "../../../utils/boolean.ts";
import {
  getSalesNameOptions,
  getSalesTeamOptions,
} from "../../../utils/company.ts";
import { toCurrencyPrice } from "../../../utils/number.ts";
import { getPagesCount } from "../../../utils/pagination.ts";
import ConditionPrecedentsModal from "./ConditionPrecedentsModal/ConditionPrecedentsModal.tsx";
import ExceptionModal from "./ExceptionModal/ExceptionModal.tsx";
import { useTransactionHistoryTableData } from "./hooks/useTransactionHistoryTableData.tsx";
import { ModalType, TransactionHistoryFiltersValue } from "./types.ts";
import {
  getTransactionHistoryApiFilter,
  getTransactionHistoryRequestApiSort,
} from "./utils.ts";

interface TransactionHistoryListProps {
  customer: Pick<Customer, "id" | "name"> | null | undefined;
}

const TransactionHistoryList = ({ customer }: TransactionHistoryListProps) => {
  const [filter, setFilter] = useState<TransactionHistoryFiltersValue>({});
  const apiFilter = useMemo(() => {
    return getTransactionHistoryApiFilter(filter);
  }, [filter]);
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<TransactionHistoryOrderBy>({
    orderDate: OrderBy.Desc,
  });

  const [selectedLimitRequestId, setSelectedLimitRequestId] = useState<
    number | null | undefined
  >(null);
  const [selectedTransactionHistory, setSelectedTransactionHistory] = useState<
    TransactionHistory | null | undefined
  >(null);
  const [selectedConditionPrecedents, setSelectedConditionalPrecedents] =
    useState<LimitRequestConditionPrecedent[] | null | undefined>(null);
  const [selectedExceptions, setSelectedExceptions] = useState<
    LimitRequestException[] | null | undefined
  >(null);

  const [
    conditionPrecedentEditModalOpened,
    {
      open: conditionPrecedenceEditModalOpen,
      close: conditionPrecedenceEditModalClose,
    },
  ] = useDisclosure(false);
  const showConditionPrecedentEditModal =
    conditionPrecedentEditModalOpened &&
    selectedLimitRequestId &&
    selectedConditionPrecedents;

  const [
    rejectConfirmationModalOpened,
    { open: openRejectConfirmationModal, close: closeRejectConfirmationModal },
  ] = useDisclosure(false);
  const showRejectConfirmationModal =
    rejectConfirmationModalOpened && selectedTransactionHistory;

  const [
    approveConfirmationModalOpened,
    {
      open: openApproveConfirmationModal,
      close: closeApproveConfirmationModal,
    },
  ] = useDisclosure(false);
  const showApproveConfirmationModal =
    approveConfirmationModalOpened && selectedTransactionHistory;

  const [
    exceptionEditModalOpened,
    { open: exceptionEditModalOpen, close: exceptionEditModalClose },
  ] = useDisclosure(false);
  const showExceptionEditModal =
    exceptionEditModalOpened && selectedLimitRequestId && selectedExceptions;

  const [{ data, fetching }, refresh] = useGetTransactionHistoryList({
    filter: { ...apiFilter, customerId: { equals: customer?.id } },
    orderBy,
    page,
  });

  const [{ data: companiesData }] = useCustomers({});

  const companies = companiesData?.customers;

  const [, approveTransactionHistory] = useMutationNotificationWrapper(
    useApproveTransactionHistory(),
    {
      success: {
        message: "Transaction History has been approved.",
      },
    }
  );
  const [, rejectTransactionHistory] = useMutationNotificationWrapper(
    useRejectTransactionHistory(),
    {
      success: {
        message: "Transaction History has been rejected.",
      },
    }
  );

  const [, updateTransactionHistory] = useMutationNotificationWrapper(
    useUpdateTransactionHistory(),
    {
      success: {
        message: "Transaction History has been updated.",
      },
    }
  );

  const transactionHistoryData: TransactionHistory[] | undefined | null =
    data?.transactionHistory?.data;

  const pageCount = getPagesCount(data?.transactionHistory?.total);

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getTransactionHistoryRequestApiSort(sort));
    },
    [setOrderBy]
  );

  const handleOpenEditModal = (type: ModalType, value: TransactionHistory) => {
    setSelectedLimitRequestId(value.limit?.limitRequest?.id);
    if (type === ModalType.ConditionPrecedents) {
      setSelectedConditionalPrecedents(
        value.limit?.limitRequest?.conditionPrecedents
      );
      return conditionPrecedenceEditModalOpen();
    }

    if (type === ModalType.Exceptions) {
      setSelectedExceptions(value.limit?.limitRequest?.exceptions);
      return exceptionEditModalOpen();
    }
  };

  const handleCloseConditionPrecedenceModal = () => {
    setSelectedConditionalPrecedents(null);
    setSelectedLimitRequestId(null);
    conditionPrecedenceEditModalClose();
  };

  const handleCloseExceptionModal = () => {
    setSelectedExceptions(null);
    setSelectedLimitRequestId(null);
    exceptionEditModalClose();
  };

  const handleUpdate = useCallback(
    (id: number, input: UpdateTransactionHistoryInput) => {
      updateTransactionHistory({ id, input }).then(refresh);
    },
    [updateTransactionHistory, refresh]
  );

  const handleFilterChange = useCallback((key: string, value: unknown) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, [key]: value };
    });
  }, []);

  const handleOpenApproveConfirmation = useCallback(
    (id: number) => {
      setSelectedTransactionHistory(
        transactionHistoryData?.find((item) => item.id === id)
      );
      openApproveConfirmationModal();
    },
    [openApproveConfirmationModal, transactionHistoryData]
  );

  const handleCloseApproveConfirmation = useCallback(() => {
    closeApproveConfirmationModal();
    setSelectedTransactionHistory(null);
  }, [closeApproveConfirmationModal]);

  const handleOpenRejectConfirmation = useCallback(
    (id: number) => {
      setSelectedTransactionHistory(
        transactionHistoryData?.find((item) => item.id === id)
      );
      openRejectConfirmationModal();
    },
    [openRejectConfirmationModal, transactionHistoryData]
  );

  const handleCloseRejectConfirmation = useCallback(() => {
    closeRejectConfirmationModal();
    setSelectedTransactionHistory(null);
  }, [closeRejectConfirmationModal]);

  const handleApprove = useCallback(() => {
    if (!selectedTransactionHistory) {
      return;
    }
    approveTransactionHistory({ id: selectedTransactionHistory.id }).then(
      () => {
        refresh();
        handleCloseApproveConfirmation();
      }
    );
  }, [
    approveTransactionHistory,
    handleCloseApproveConfirmation,
    refresh,
    selectedTransactionHistory,
  ]);

  const handleReject = useCallback(() => {
    if (!selectedTransactionHistory) {
      return;
    }
    rejectTransactionHistory({ id: selectedTransactionHistory.id }).then(() => {
      refresh();
      handleCloseRejectConfirmation();
    });
  }, [
    selectedTransactionHistory,
    rejectTransactionHistory,
    refresh,
    handleCloseRejectConfirmation,
  ]);

  const [columns, rows] = useTransactionHistoryTableData({
    data: transactionHistoryData,
    onApprove: handleOpenApproveConfirmation,
    onReject: handleOpenRejectConfirmation,
    onOpenEditModal: handleOpenEditModal,
    onInlineEdit: handleUpdate,
  });

  return (
    <Flex direction="column" gap={16}>
      <SimpleGrid
        w="100%"
        cols={3}
        breakpoints={[
          { maxWidth: "sm", cols: 1 },
          { maxWidth: "lg", cols: 2 },
        ]}
        spacing={20}
        verticalSpacing={12}
      >
        <Select
          size="m"
          clearable
          searchable
          label="Sales Name"
          placeholder="Select Sales Name"
          data={getSalesNameOptions(companies)}
          onChange={(value) => handleFilterChange("salesName", value)}
        />
        <Select
          size="m"
          clearable
          searchable
          label="Payment Status"
          placeholder="Select Payment Status"
          data={paymentStatusData}
          onChange={(value: PaymentStatus) =>
            handleFilterChange("paymentStatus", value)
          }
        />
        <Select
          size="m"
          clearable
          searchable
          label="Has Exceptions"
          placeholder="Select if it has exceptions"
          data={boolSelectorOptions}
          onChange={(value) =>
            handleFilterChange("hasException", stringToBoolean(value))
          }
        />
        <Select
          size="m"
          clearable
          searchable
          label="Sales Team"
          placeholder="Select Sales Team"
          data={getSalesTeamOptions(companies)}
          onChange={(value) => handleFilterChange("salesTeam", value)}
        />
        <Select
          size="m"
          clearable
          searchable
          label="Payment Type"
          placeholder="Select Payment Type"
          data={paymentTypeData}
          onChange={(value) => handleFilterChange("paymentType", value)}
        />
        <Select
          size="m"
          clearable
          searchable
          label="Is Restructured"
          placeholder="Select if it is Restructured"
          data={boolSelectorOptions}
          onChange={(value) =>
            handleFilterChange("isRestructured", stringToBoolean(value))
          }
        />
      </SimpleGrid>
      <Table
        columns={columns}
        rows={rows}
        verticalAlignment="top"
        defaultSort={{
          columnAccessor: "createdAt",
          direction: SortDirection.asc,
        }}
        loading={fetching}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onSortChange={handleOnSortChange}
      />
      {showConditionPrecedentEditModal && (
        <ConditionPrecedentsModal
          limitRequestId={selectedLimitRequestId}
          data={selectedConditionPrecedents}
          opened={true}
          onClose={() => {
            handleCloseConditionPrecedenceModal();
            refresh();
          }}
        />
      )}
      {showExceptionEditModal && (
        <ExceptionModal
          limitRequestId={selectedLimitRequestId}
          data={selectedExceptions}
          opened={true}
          onClose={() => {
            handleCloseExceptionModal();
            refresh();
          }}
        />
      )}
      {showApproveConfirmationModal && (
        <ConfirmationModal
          opened={true}
          message={
            <>
              Are you sure you want to APPROVE the transaction for{" "}
              {customer?.name}, {selectedTransactionHistory?.quotationNo}, with
              the total amount of{" "}
              {toCurrencyPrice(selectedTransactionHistory?.totalOrderAmount)}?{" "}
              <br />
              This process is Irreversible.
            </>
          }
          onConfirm={handleApprove}
          onClose={handleCloseApproveConfirmation}
        />
      )}
      {showRejectConfirmationModal && (
        <ConfirmationModal
          opened={true}
          message={
            <>
              Are you sure you want to REJECT the transaction for{" "}
              {customer?.name}, {selectedTransactionHistory?.quotationNo}, with
              the total amount of{" "}
              {toCurrencyPrice(selectedTransactionHistory?.totalOrderAmount)}?
              <br />
              This process is Irreversible, no changes can be made on the
              transaction after this.
            </>
          }
          onConfirm={handleReject}
          onClose={handleCloseRejectConfirmation}
        />
      )}
    </Flex>
  );
};

export default TransactionHistoryList;
