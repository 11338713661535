import { Flex } from "@mantine/core";

import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import NewLimitRequestedTable from "./NewLimitRequested/NewLimitRequestedTable.tsx";
import OngoingCUTable from "./OngoingCU/OngoingCUTable.tsx";

const CreditUnderwritingPage = () => {
  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Credit Underwriting</Title>
        <NewLimitRequestedTable />
        <OngoingCUTable />
      </Flex>
    </Layout>
  );
};

export default CreditUnderwritingPage;
