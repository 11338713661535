import { Flex, Select, SimpleGrid } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import SvgWarning from "../../../components/Icons/Warning.tsx";
import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../components/Table/Table.tsx";
import { SortDirection, TableRow } from "../../../components/Table/types.ts";
import { AppRoute } from "../../../constants.ts";
import {
  LimitRequestFilter,
  LimitRequestOrderBy,
  LimitRequestStatusFilterOptions,
  OrderBy,
  UpdateCuAssignedInput,
} from "../../../graphql/generated.ts";
import { useUpdateCUAssigned } from "../../../hooks/api/creditLimit/useUpdateCUAssigned.ts";
import { useCustomers } from "../../../hooks/api/customer/useCustomers.ts";
import { useLimitRequests } from "../../../hooks/api/limitRequest/useLimitRequests.ts";
import { useCUAssignees } from "../../../hooks/api/user/useCUAssignees.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { LimitRequest } from "../../../types/limitRequest.ts";
import {
  getCompanyOptions,
  getSalesNameOptions,
  getSalesTeamOptions,
} from "../../../utils/company.ts";
import { getPagesCount } from "../../../utils/pagination.ts";
import { getUserOptions } from "../../../utils/user.ts";
import { getLimitRequestApiFilter, getLimitRequestApiSort } from "../utils.ts";
import { useNewLimitRequestedTableData } from "./hooks/useNewLimitRequestedTableData.tsx";

const NewLimitRequestedTable = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<LimitRequestFilter>({});
  const [orderBy, setOrderBy] = useState<LimitRequestOrderBy>({
    createdAt: OrderBy.Desc,
  });
  const [page, setPage] = useState<number>(1);

  const [{ data, fetching, error }, refreshData] = useLimitRequests({
    filter: {
      ...filter,
      requestStatus: {
        equals: LimitRequestStatusFilterOptions.NewLimitRequest,
      },
    },
    orderBy,
    page,
  });

  const [{ data: assigneesData }] = useCUAssignees();

  const assignees = assigneesData?.users?.data;

  const [{ data: companiesData }] = useCustomers({});

  const companies = companiesData?.customers;

  const [, updateCUAssigned] = useMutationNotificationWrapper(
    useUpdateCUAssigned(),
    {
      success: { message: "CU Assigned has been successfully updated." },
    }
  );

  const newLimitRequests: LimitRequest[] | undefined | null =
    data?.limitRequests?.data;

  const pageCount = getPagesCount(data?.limitRequests?.total);

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getLimitRequestApiSort(sort));
    },
    [setOrderBy]
  );

  const handleEditCUAssigned = useCallback(
    (id: number, input: UpdateCuAssignedInput) => {
      updateCUAssigned({ id, input }).then(refreshData);
    },
    [updateCUAssigned, refreshData]
  );

  const handleFilterChange = useCallback(
    (key: string, value: string | number | null | undefined) => {
      setFilter(getLimitRequestApiFilter({ [key]: value }));
    },
    []
  );

  const handleRowClik = useCallback(
    (record: TableRow) => {
      navigate(`${AppRoute.LimitRequest}/${record.id}`);
    },
    [navigate]
  );

  const [columns, rows] = useNewLimitRequestedTableData({
    data: newLimitRequests,
    assignees,
    onEditCUAssigned: handleEditCUAssigned,
  });

  if (error) {
    notifications.clean();
    notifications.show({
      message:
        "Something went wrong while trying to fetch Limit Requests data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <Flex direction="column" gap={12}>
      <SectionTitle>New Limit Requested</SectionTitle>
      <SimpleGrid
        cols={3}
        breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        spacing={20}
        verticalSpacing={12}
        pb={8}
      >
        <Select
          size="m"
          clearable
          searchable
          label="Sales Name"
          placeholder="Select Sales Name"
          data={getSalesNameOptions(companies)}
          onChange={(value) => handleFilterChange("salesName", value)}
        />
        <Select
          size="m"
          clearable
          searchable
          label="Sales Team"
          placeholder="Select Sales Team"
          data={getSalesTeamOptions(companies)}
          onChange={(value) => handleFilterChange("salesTeamName", value)}
        />
        <Select
          size="m"
          clearable
          searchable
          label="Company name"
          placeholder="Select Company name"
          data={getCompanyOptions(companies)}
          onChange={(value) => handleFilterChange("companyName", value)}
        />
        <Select
          size="m"
          clearable
          searchable
          label="CU Assigned"
          placeholder="Select CU Assigned"
          data={getUserOptions(assignees)}
          onChange={(value) =>
            handleFilterChange("cuAssignedId", Number(value))
          }
        />
      </SimpleGrid>
      <Table
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "createdAt",
          direction: SortDirection.asc,
        }}
        loading={fetching}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onRowClick={handleRowClik}
        onSortChange={handleSortChange}
      />
    </Flex>
  );
};

export default NewLimitRequestedTable;
