import {
  ActionIcon as MantineActionIcon,
  Button,
  Flex,
  MediaQuery,
  SimpleGrid,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { filter } from "lodash";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import MultiFieldCard from "../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SvgChevronLeft from "../../components/Icons/ChevronLeft.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import LimitRequestInfoForm from "../../components/LimitRequestInfoForm/LimitRequestInfoForm.tsx";
import AddPengurusButton from "../../components/Pengurus/AddPengurusButton/AddPengurusButton.tsx";
import PengurusFormModal from "../../components/Pengurus/PengurusFormModal/PengurusFormModal.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import SectionTitle from "../../components/Section/SectionTitle/SectionTitle.tsx";
import { useClikCities } from "../../hooks/api/clikData/useClikCities.ts";
import { useClikCountries } from "../../hooks/api/clikData/useClikCountries.ts";
import { useCompanies } from "../../hooks/api/customer/useCompanies.ts";
import { useCreateLimitRequest } from "../../hooks/api/limitRequest/useCreateLimitRequest.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { RawLimitRequest } from "../../types/limitRequest.ts";
import { RawPengurus } from "../../types/pengurus.ts";
import { usePengurusesInfo } from "./hooks/usePengurusesInfo.tsx";
import { useStyles } from "./LimitRequestCreate.styles.ts";
import { formatLimitRequestToCreateLimitRequestInput } from "./utils.ts";

const LimitRequestCreatePage = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const [value, setValue] = useState<RawLimitRequest | null>(null);

  const [valid, setValid] = useState(false);

  const [penguruses, setPenguruses] = useState<RawPengurus[]>([]);

  const [selectedPengurus, setSelectedPengurus] = useState<{
    index: number;
    data: RawPengurus;
  } | null>(null);

  const [{ data: clikCitiesData, fetching: clikCitiesFetching }] =
    useClikCities();

  const [{ data: clikCountriesData, fetching: clikCountriesFetching }] =
    useClikCountries();

  const [{ data: companiesData, fetching: companiesFetching }] = useCompanies();
  const customers = companiesData?.customers;

  const [, createLimitRequest] = useMutationNotificationWrapper(
    useCreateLimitRequest(),
    {
      success: {
        message: "Limit Request has been added successfully.",
      },
    }
  );

  const [
    pengurusModalOpened,
    { open: pengurusModalOpen, close: pengurusModalClose },
  ] = useDisclosure(false);

  const handleValueChange = (value: RawLimitRequest, valid: boolean) => {
    setValue(value);
    setValid(valid);
  };

  const handleBack = () => navigate(-1);

  const handleSubmit = () => {
    if (!value) {
      return;
    }
    const input = formatLimitRequestToCreateLimitRequestInput({
      ...value,
      penguruses,
    });

    if (!input) {
      return;
    }
    createLimitRequest({ input }).then(() => {
      handleBack();
    });
  };

  const handleCancel = () => {
    setValue(null);
    handleBack();
  };

  const handlePengurusModalClose = () => {
    setSelectedPengurus(null);
    pengurusModalClose();
  };

  const handleStartEditPengurus = (value: RawPengurus, index: number) => {
    setSelectedPengurus({ index, data: value });
    pengurusModalOpen();
  };

  const handleSavePengurus = (value: RawPengurus) => {
    if (selectedPengurus) {
      handleEditPengurus(value);
    } else {
      handleAddPengurus(value);
    }
  };

  const handleAddPengurus = (value: RawPengurus) =>
    setPenguruses((prevValue) => [...prevValue, value]);

  const handleEditPengurus = (value: RawPengurus) => {
    if (!selectedPengurus) {
      return;
    }
    setPenguruses((prevArray) => {
      return prevArray.map((v, i) => {
        if (i === selectedPengurus.index) {
          return value;
        }
        return v;
      });
    });
  };

  const handleDeletePengurus = (index: number) => {
    setPenguruses((prevValue) => filter(prevValue, (_, i) => i !== index));
  };

  const pengurusesInfoCards = usePengurusesInfo({
    penguruses,
    onEdit: handleStartEditPengurus,
    onDelete: handleDeletePengurus,
  });

  const isFetching = useMemo(() => {
    return clikCitiesFetching || clikCountriesFetching || companiesFetching;
  }, [clikCitiesFetching, companiesFetching, clikCountriesFetching]);

  return (
    <>
      <Layout>
        <Preloader loading={isFetching} />
        <Flex direction="column" className={classes.wrapper}>
          <Flex
            justify="space-between"
            align="center"
            pb={{ base: 16, sm: 24 }}
          >
            <Flex gap={20} align="center">
              <MantineActionIcon
                size="m"
                variant="outlineGolden"
                onClick={handleBack}
              >
                <SvgChevronLeft />
              </MantineActionIcon>
              <Title className={classes.titleWrapper}>Add Limit Request</Title>
            </Flex>
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <Flex gap={20}>
                <Button
                  variant="outlineGolden"
                  type="reset"
                  uppercase
                  onClick={handleCancel}
                >
                  cancel
                </Button>
                <Button
                  variant="filledGolden"
                  size="m"
                  type="submit"
                  disabled={!valid}
                  uppercase
                  onClick={handleSubmit}
                >
                  submit
                </Button>
              </Flex>
            </MediaQuery>
          </Flex>
          <Flex direction="column" gap={{ base: 16, sm: 20 }}>
            <LimitRequestInfoForm
              value={value}
              cities={clikCitiesData?.clikCities}
              countries={clikCountriesData?.clikCountries}
              companies={customers}
              onValueChange={handleValueChange}
            />
            <Flex direction="column" gap={{ base: 16, sm: 20 }}>
              <SectionTitle flex={0}>Pengurus List</SectionTitle>
              <SimpleGrid
                cols={1}
                verticalSpacing={16}
                breakpoints={[
                  { minWidth: "sm", cols: 2, verticalSpacing: 20 },
                  { minWidth: "lg", cols: 3, verticalSpacing: 20 },
                ]}
                spacing={20}
              >
                <AddPengurusButton onClick={pengurusModalOpen} />
                {pengurusesInfoCards?.map((item, index) => (
                  <MultiFieldCard key={index} variant="blue" {...item} />
                ))}
              </SimpleGrid>
            </Flex>
          </Flex>
        </Flex>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Flex gap={20} className={classes.buttonsWrapper}>
            <Button
              variant="outlineGolden"
              type="reset"
              uppercase
              w="100%"
              onClick={handleCancel}
            >
              cancel
            </Button>
            <Button
              variant="filledGolden"
              size="m"
              type="submit"
              uppercase
              w="100%"
              disabled={!valid}
              onClick={handleSubmit}
            >
              submit
            </Button>
          </Flex>
        </MediaQuery>
      </Layout>
      {pengurusModalOpened && (
        <PengurusFormModal
          value={selectedPengurus?.data ?? null}
          cities={clikCitiesData?.clikCities}
          countries={clikCountriesData?.clikCountries}
          opened={true}
          onClose={handlePengurusModalClose}
          onSubmit={handleSavePengurus}
        />
      )}
    </>
  );
};

export default LimitRequestCreatePage;
