import { Flex } from "@mantine/core";

import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import NewCURecommendationTable from "./NewCURecommendation/NewCURecommendationTable.tsx";
import OngoingRRTable from "./OngoingRR/OngoingRRTable.tsx";

const RiskReviewPage = () => {
  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Risk Review</Title>
        <NewCURecommendationTable />
        <OngoingRRTable />
      </Flex>
    </Layout>
  );
};

export default RiskReviewPage;
